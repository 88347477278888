<template>
  <div style="padding: 0 10px;">
    <img src="@/image/bg_05.png" class="bgImg" alt="">
    <div class="LP_box0" style="position: relative;overflow:hidden;">
      <img style="position:absolute;height: 120px;right:-40px;top: 10px;z-index: -1;opacity: 0.4;" src="@/image/bg_07.png" alt="">
      <!-- 当前价格 -->
      <div class="flex_lr">
        <div style="width: 50px">
          <img src="@/image/icon_01.png" style="width:100%;" alt="">
        </div>
        <div class="y_center">
          <div class="flex_row">
            <i @click="openInformation('title')" class="ifont icone-bangzhu hand" style="color: #00FFEA;margin-right:5px;font-size:18px;"></i>
            <div class="LP_title" style="padding:0;font-size:1.2rem;">MT激励基金池</div>
          </div>
          <div class="LP_title" style="padding:0;font-size:0.9rem;" v-if="info.allPower!=undefined">{{fromWei(info.allPower[27])}}MT</div>
        </div>
        <div style="width: 50px"></div>
      </div>
      <div class="y_center" style="padding: 20px 0;">
        <div class="LP_title" style="padding:0;font-size:1.1rem;">倒计时</div>
        <div class="LP_title" style="padding:0;font-size:1.2rem;">{{info.new_time}}</div>
      </div>
      <div class="nav flex_lr">
        <div class="nav_item flex_center" :style="'background:'+(nav==0?'#1332B7':'#081858')" @click="changeNav(0)">大单农耕排名池</div>
        <div class="nav_item flex_center" :style="'background:'+(nav==1?'#1332B7':'#081858')" @click="changeNav(1)">邀请农耕排名池</div>
      </div>
    </div>

    <!--  -->
    <div class="LP_box0 y_center" style="padding: 10px;">
      <div class="flex_lr" style="width:100%;">
        <div style="width:80px;"></div>
        <div class="LP_title flex_row" style="font-size: 1.2rem;">
          <i @click="openInformation('nav'+nav)" class="ifont icone-bangzhu hand" style="color: #00FFEA;margin-right:5px;font-size:18px;"></i>
          <div>{{nav==1?'邀请农耕排名池':'大单农耕排名池'}}</div>
        </div>
        <div class="flex_lr" style="width: 80px;">
          <div></div>
          <div class="flex_row" @click="openLog">
            <i class="ifont icone-mingxi LP_title" style="margin-right: 3px;" />
            <div class="LP_title">记录</div>
          </div>
        </div>
      </div>
      <div class="flex_row" v-if="info.allPower!=undefined">
        <div class="LP_title" style="padding: 5px 0;font-size: 0.9rem;">{{(fromWei(info.allPower[27])*(0.2+nav/10)).toFixed(6)}}MT</div>
        <div class="LP_box3 LP_text" v-if="info.allPower[31]<=info.allPower[29]">未达标</div>
        <div class="LP_box3 LP_text" @click="take" v-if="user.info!=undefined&&Number(user.info[39+nav])+fromWei(info.allPower[27])*info.percentage>0" style="background: #1332b7;">可领取{{(fromWei(user.info[39+nav])+fromWei(info.allPower[27])*info.percentage).toFixed(6)}}</div>
      </div>

    </div>
    <!-- 表 -->

      <el-table :data="tableData" :header-cell-style="{background:'#0C2379',color:'#ffffff'}" :cell-style="{background:'rgb(126 133 157)',color:'#ffffff'}" style="width: 100%;overflow:auto;margin-top:10px;" cell-class-name="success-row" row-class-name="table_row" >
        <el-table-column type="index" align="center" width="50" :label="$t('weekRanking.text6')" />
        <el-table-column prop="address" align="center" :label="$t('weekRanking.text8')">
          <template slot-scope="scope">
            {{ scope.row.address.slice(0,3)+'*********'+scope.row.address.slice(scope.row.address.length-1) }}
          </template>
        </el-table-column>
        <el-table-column prop="count" align="center" width="100" :label="nav==1?'邀请农耕数量':'大单额度'"/>
      </el-table>
    <div style="height: 20px;"></div>
    <!-- 说明彈窗 -->
    <information v-if="show_information" @close="show_information=false" :head="$t('Incentive.'+chose_information+'.title')" :content="$t('Incentive.'+chose_information+'.content')" />
  </div>
</template>
 
<script>
import walletHelper from "@/utils/walletHelper.js"
import {aggregate} from "@/utils/multicall.js"
import UpLevelData from "@/components/UpLevelData.vue"

import { Loading } from 'element-ui';

export default {
  components: {
    UpLevelData,
  },
  data () {
    return {
      nav:0,
      thread:0,
      tableData:[],
      limit_min:[5,5],
      info:{percentage:0},
      user:[],
      balance:0,
      show_information:false,
      chose_information:''
    }
  },
  computed: {
    	userInfo() {
			return this.$store.state.user.userInfo
		},
	},
  created() {
    // window.addEventListener('scroll', this.Scrollbottom);  //页面加载时监听滚动事件
  },
  destroyed() {
    // window.removeEventListener('scroll', this.Scrollbottom) //页面离开后销毁监听事件
  },
  async mounted () {
    this.DEBUG=process.env.VUE_APP_DEBUG

    await this.$onLaunched;
        if(this.$route.query.address!=undefined){
      this.$store.commit('user/SET_WALLET_ADDRESS', this.$route.query.address)
    }

    this.init()
  },
  beforeDestroy(){
    if(this.thread>0){
      clearInterval(this.thread)
    }
  },
  methods: {
    openInformation(name){
      this.chose_information=name
      this.show_information=true
    },
    openLog(){
      // this.$router.replace('/IncentiveLog?type='+this.nav+'&index=2605').catch(err => err)
      const res=this.info.allPower
      let text='&index='+(Number(this.info.new_index)-1)
      if(Number(res[30])<Number(this.info.new_index)&&res[31]>res[29]&&res[27]>0){
        text='&days='+res[30]+'&money='+res[27]
      }
        // text='&days=2643&money=5'
      this.$router.push('/IncentiveLog?type='+this.nav+'&min='+this.limit_min[this.nav]+text)
    },
    changeNav(type){
      this.nav=type
      this.info.percentage=0
      this.getRankingBuy()
      this.getRankingBuyYes()
    },
    getUintByte(count,index){
      console.log(count/Number(10**index))
        return Math.floor(count/Number(100**index))%100;
    },
    init() {
      const that=this
      // this.testPair()
      walletHelper.getContract('mt').methods.balanceOf(walletHelper.getAddress()).call().then(res=>{
          console.log(res)
          that.balance=walletHelper.Wei(res)
      })
      walletHelper.getContract('daoV1').methods.getAllPower().call().then(res=>{
        console.log(res)
        that.$set(that.info,'allPower',res)

        that.limit_min=[this.getUintByte(that.info.allPower[25],5),this.getUintByte(that.info.allPower[25],6)]
        console.log(that.limit_min,that.info.allPower[25])

        let time=Math.ceil(new Date().getTime()/1000)
        walletHelper.getContract('daoV1').methods.indexFromTime(time).call().then(index=>{
          console.log('new_index',index)
          that.$set(that.info,'new_index',index)
          that.getDayTime()
          that.getRankingBuy()
          walletHelper.getUserData(walletHelper.getAddress(),(res,e)=>{
            console.log(56655,res)
            if(res){
              that.user=res
              that.getRankingBuyYes()
              
            }else{
              console.log('getUserData错误  error',e)
            }
          })
          
        })
      })



    },
    testPair(){
      const tokens=['0xbf4707B7f9F53e3aAE29Bf2558CB373419Ef4D45']
      const names=['mt']
      const data=[]
      for(let i in tokens){
        data.push(
          {
            target: tokens[i],
            call: ['getReserves()(uint112,uint112,uint112)'],
            returns :[['res0,'+i],['res1,'+i],[]],
          },
          {
            target: tokens[i],
            call: ['totalSupply()(uint256)'],
            returns :[['totalSupply,'+i]],
          },
        )
      }

      let liquidity=7.561734466961070313


      walletHelper.multicall(data,1).then(res=>{
        console.log(res)
        for(let i in res){
          let res0=liquidity*res[i].res0/res[i].totalSupply
          let res1=liquidity*res[i].res1/res[i].totalSupply
          console.log(names,res0,res1)
        }
      })

    },
    fromWei1(val){
      return walletHelper.getWeb3().utils.fromWei(val.toString())
    },
    getRankingBuyYes(){
      const that=this
      const res=that.info.allPower
      if(Number(res[30])<Number(that.info.new_index)&&res[31]>res[29]&&res[27]>0){
        let days=that.nav*1000000+Number(res[30])
        let user_index=36+that.nav*2
        let len=that.nav==0?4:10
        walletHelper.getContract('dao390New').methods.getRankingBuy(days,len,user_index).call().then(list=>{
        console.log('days',days,user_index,list)

          let address=walletHelper.getAddress().toUpperCase()
          for(let i=0;i<len;i++){

            if(list[i]&&list[i].toUpperCase()==address&&Number(that.user.info[user_index])>=Number(that.limit_min[that.nav])){

              that.getPercentage(i)
              return
            }
          }
        })
      }
    },
    getRankingBuy(){
      const that=this

      const loading =Loading.service({
            lock: true,
            text: '查询中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });

      let days=that.nav*1000000+Number(that.info.new_index)
      // let days=that.nav*1000000+2605
      let user_index=36+that.nav*2
      walletHelper.getContract('dao390New').methods.getRankingBuy(days,10,user_index).call().then(list=>{
          console.log('排名',list)
          that.tableData=[]
          const data=[]
          for(let i=0;i<10;i++){
            const info={
              address:'0x0000000000000000000000000000000000000000',
              count:0
            }
            if(list.length>i){
              info.address=list[i]
              data.push({
                  target: walletHelper.getContractAddress('daoV1'),
                  call: ['data(address,uint256)(uint256)',list[i],user_index],
                  returns :[['fee,'+i,res=>{return res.toString()}]],
              })

            }
            that.tableData.push(info)
            
          }
          loading&&loading.close();

          if(data.length>0){
            walletHelper.multicall(data,1).then(res=>{
              // console.log(res)
              for(let i in res){
                let count=res[i].fee
                if(that.nav==0){
                  count=count*390
                }
                that.$set(that.tableData[i],'count',count)
              }
            })
          }
        })

    },
    getPercentage(i){
      const that=this
      if(this.nav==0){
        if(i<4){
          that.$set(that.info,'percentage',(4-i)*2/100)
        }
      }else{
        if(i==0){
          that.$set(that.info,'percentage',0.12)
        }else if(i<10){
          that.$set(that.info,'percentage',i<=3?0.03:0.015)
        }
      }
    },
    getDayTime(){
      const that=this

      walletHelper.getContract('daoV1').methods.indexToTime(this.info.new_index).call().then(index=>{
        console.log('new_time',index,new Date(Number(index+'000')).toString())
        let time=Math.ceil(new Date().getTime()/1000)
        if(time>index){
          this.info.new_index++
          this.init()
        }else{
          let value=Number(index)-time
          that.$set(that.info,'time_value',value)
          that.$set(that.info,'new_time',that.getTime(value))
          if(this.thread>0){
            clearInterval(this.thread)
          }
          that.thread=setInterval(()=>{
            if(that.info.time_value<=0){
              this.init()
            }else{
              that.info.time_value--
              that.$set(that.info,'new_time',that.getTime(that.info.time_value))
            }

          },1000)
        }
      })
    },
    getTime(time) {
        // 转换为式分秒
        let h = parseInt(time / 60 / 60 % 24)
        h = h < 10 ? '0' + h : h
        let m = parseInt(time / 60 % 60)
          m = m < 10 ? '0' + m : m
        let s = parseInt(time % 60)
          s = s < 10 ? '0' + s : s
        // 作为返回值返回
        // return [h, m, s]
        return `${h}:${m}:${s}`

    },
    fromWei(wei){
      return wei?walletHelper.Wei(wei):0
    },
    async take(){
      const that=this
      if(walletHelper.isNotTranfer()){
        return
      }
      let count=this.fromWei(this.user.info[39+this.nav])+this.fromWei(this.info.allPower[27])*this.info.percentage
      if(Number(count)>Number(this.balance)){
        this.$toast('MT余额不足')
        return
      }
      this.sendWeb3(walletHelper.getContract('dao390Take').methods.takeRanking()).then((result)=>{
        console.log(result)
        that.init()
        that.$toast('提取成功')
      })
      
    },
    Scrollbottom() {
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        this.next_pages()
      }
    },
    next_pages(){
      if(this.tableData!=undefined&&this.tableData.length>0){
				this.current_page++
				if (this.last_page<this.current_page) {
					return
				}
				this.init()
			}
    },
    copy(text){
      this.$copyText(text).then(e=>{
        this.$toast(this.$t('tips.title2'))
      }, e=> {
        this.$toast(this.$t('tips.title3'))
      })
    },
  },
}
</script>
<style lang="scss">
// 图表
.el-table {
  font-family: simsong;
  color: #ffffff !important;
  background: #041123;
  font-size: 0.76rem !important;
}
.el-table__header-wrapper{
  border-radius: 5px !important;
  overflow: hidden;
}
.el-table__body tr:hover > td {
  background-color: transparent !important;
}
.el-table--border .el-table__cell {
  border: none;
}
.el-table tr:nth-of-type(1n+0) {
  background: #020b1b;
}
.el-table tr:nth-of-type(2n+0) {
  background: #081850;
}
.el-table td{
  border: none !important;
}
.el-table th{
  border-bottom: none !important;
}
.el-table::before{
  width: 0 !important;
}
.el-table .success-row{
  background: none !important;
}
</style>
<style lang="scss" scoped>
  .nav{
    width: 100%;
    border-radius: 10px;
    overflow: auto;
    .nav_item{
      width: 50%;
      padding: 10px 0;
      color: #ffffff;
      font-size: 0.9rem;
    }
  }
  .pledge_num{
    width:20px;
    height:20px;
    border-radius:50%;
    border: 2px solid #ffffff;
    color: #ffffff;
    font-size: 0.8rem;
  }
  .notice{
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    .notice_box{
      position: relative;
      z-index: 3;
      width: 80%;
      background: rgba(#00BABC,0.5);
      border-radius: 10px;
      padding: 10px;
      color: #00b5d1;
      .notice_box2{
        background: rgba(#000000,0.7);
        border-radius: 10px;
        padding: 10px;
        .notice_title{
          font-size: 1.1rem;
          color: #00DEFF;
          margin-bottom: 10px;
        }
        .notice_title2{
          font-size: 0.95rem;
          color: #00DEFF;
          margin-bottom: 10px;
        }
        .notice_content{
          font-size: 0.9rem;
          height: 240px;
          overflow: auto;
        }
        .notice_btn{
          width:100%;
          height: 40px;
          background: #00a5a8;
          color: #000000;
          font-size: 1rem;
          border-radius: 5px;
          margin-top: 10px;
        }
      }
    }
  }
  .bgImg{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: -1;
  }
  .toPay{
    font-size: 0.8rem;
    font-family: SimHei;
    color: #00E7D5;
    border-radius: 50px;
    border: 1px solid #00E7D5;
    padding: 3px 5px;
    img{
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .LP_title{
    color: #00FFEA;
    font-size: 1rem;
    padding: 10px 0;
  }
  .LP_text{
    color: #ffffff;
    font-size: 0.85rem;
    padding: 5px 0;
  }
  .LP_num{
    color: #00FFEA;
    margin-left: 10px;
  }
  .LP_box0{
    width: 100%;
    background: rgba(12,35,129,0.3);
    border-radius:5px;
    padding:10px;
    margin-bottom: 10px;
  }
  .LP_box1{
    width: 100%;
    background: #1332B7;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
    .LP_title{
      color: #ffffff;
    }
  }
  .LP_box2{
    width: 100%;
    background: #041453;
    border-radius: 8px;
    padding: 10px 13px;
    margin-bottom: 10px;
  }
  .LP_box3{
    background: #9f9da3;
    border-radius: 8px;
    padding: 4px 8px;
    margin: 4px;
  }
  .LP_num1{
    text-align: right;
    font-size: 0.8rem;
    color: #ffffff;
  }
  .LP_num2{
    text-align: right;
    font-size: 0.75rem;
    color: #00FFEA;
  }
  .LP_btn{
    width: 100%;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btnh{
    width: 100%;
    text-align: center;
    background: #990000;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 15px;
    margin: 20px 0;
    white-space: nowrap;
  }
  .LP_btn2{
    text-align: center;
    background: #00FFEA;
    border-radius: 5px;
    color: #000000;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .LP_btn_after{
    text-align: center;
    background: #f0f0f0;
    border-radius: 5px;
    color: #999999;
    font-size: 0.9rem;
    padding: 5px 25px;
    white-space: nowrap;
  }
  .date_show{
    position: relative;
    z-index: 4;
    height: 20px;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 0 8px;
    i{
      font-size: 0.75rem;
      color: #1870EA;
      transform: scale(0.7);
      margin-left: 10px;
    }
  }
  .date_list{
    position: absolute;
    z-index: 3;
    right: 0;
    left: 0;
    top: 0;
    margin: auto;
    font-size: 0.75rem;
    color: #ffffff;
    background: #0c3774;
    border-radius: 5px;
    padding: 20px 8px 0;
    .date_item{
      padding: 3px 0;
    }
  }
  .address{
    width: 0;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .take{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .take_bg{
      background: #ffffff;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .take_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .take_content{
          font-size: 0.9rem;
          margin: 10px 0;
          span{
            color: #ff0000;
          }
        }
        .take_text{
          color: #999999;
          font-size: 0.8rem;
          margin: 10px 0;
          span{
            color: #ff0000;
          }
        }
        .take_btn1{
          width: 45%;
          padding: 5px 0;
          background: #21C7D5;
          border-radius: 32px;
          color: #ffffff;
          margin-top: 10px;
        }
        .take_btn2{
          width: 45%;
          padding: 5px 0;
          border: 1px solid #999999;
          border-radius: 32px;
          margin-top: 10px;
        }
      }
    }
  }
  .bind{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    font-family: PingFang SC;
    .bind_bg{
      background: #21C7D5;
      padding: 6px;
      border-radius: 7px;
      position: relative;
      z-index: 2;
      width: 90%;
      .bind_box{
        // background: rgba(#000000,0.7);
        z-index: 1000;
        padding: 15px 10px;
        border-radius: 7px;
        font-size: 16px;
        .bind_text{
          color: #CE0F0F;
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 5px;
        }
        .bind_text1{
          color: rgb(14, 245, 137);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        .bind_text2{
          color: rgb(233, 99, 22);
          font-size: 14px;
          padding: 0 5px;
          margin-bottom: 10px;
        }
        input{
          font-size: 16px;
          // width: 0;
          // flex: 1;
          width: 100%;
          padding: 15px 15px;
          background: #ffffff;
          border-radius: 3px;
          // min-height: 100px;
          color: #00DEFF;
          line-height: 18px;
          margin: 15px 0 10px;
        }
        // chrome
        input::-webkit-input-placeholder { /* WebKit browsers */
          color: #999999;
          font-size:15px;
        }
        // firefox
        input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
          color: #999999;
          font-size:15px;
        }
        button{
          background: #ffffff;
          color: #177C85;
          font-size: 14px;
          border: none;
          // margin-left: 10px;
          border-radius: 3px;
          height: 40px;
          // width: 80px;
          width: 100%;
        }
      }
    }
  }

  .pledge{
  .pledge_li{
    position: absolute;
    top:-20px;
    transform: scale(0.8) translateX(-50%);
    width:30px;
    color: #ffffff;
  }
  .pledge_box{
    width: 100%;
    background: #0C2379;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_box2{
    width: 100%;
    background: #041453;
    border-radius: 5px;
    padding: 0 10px;
    color: #fff;
  }
  .pledge_title{
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
  .pledge_text{
    font-size: 0.8rem;
    line-height: 1.5rem;
    color: #00FFEA;
  }
  .pledge_open{
    border-radius: 50%;
    border: 1px solid #00FFEA;
    color: #00FFEA;
    font-size: 0.7rem;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
  }
  .pledge_top{
    border-bottom: 1px solid #0B2FB8;
    padding: 15px 0;
    .pledge_day{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scale(0.8);
    }
  }
  .pledge_jdt{
    width: 100%;
    height: 20px;
    background: #90acaf;
    border-radius: 50px;
    overflow: hidden;
    p{
      height: 100%;
      border-radius: 50px;
      background: rgb(19, 183, 23);
    }
  }
  .pledge_input{
    background: none;
    color: #fff;
    width: 100%;
  }
  .pledge_input::-webkit-input-placeholder{color:#839CFF;}
  .pledge_input::-moz-placeholder{color:#839CFF;}
  .pledge_input:-ms-placeholder{color:#839CFF;}
  .pledge_btn{
    width: 47%;
    padding: 5px 0;
    color: #000;
    background: #00FFEA;
    border-radius: 5px;
    font-size: 0.7rem;
    margin-bottom: 10px;
  }
}
.shifang{
    background-color: #3a952a;
    text-align: center;
    background: #1332B7;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    padding: 5px;
    margin: 5px;
    white-space: nowrap;
}
.shifang1{
    background-color: #bb5a82;;
}
</style>